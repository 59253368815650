<template>
  <div class="jobApplication">
    <section class="formSection">
      <div class="formSection_form">
        <div class="formSection_form__textCont">
          <h1 class="formSection_form__textCont__logo">SPINNOV</h1>
        </div>
        <div class="formSection_form__textCont">
          <h1 class="formSection_form__textCont__jobPos">
            {{ selectedJob.title }}
          </h1>
        </div>
        <div class="formSection_form__textCont">
          <p class="formSection_form__textCont__Desc">
            {{ $t("weWantToMeetYou") }}
          </p>
        </div>
        <form class="vue-form" @submit.prevent="submit">
          <fieldset class="fieldset">
            <div>
              <label class="label" for="name">{{ $t("yourNameLblForm") }}</label>
              <input type="text" name="name" id="name" required="" v-model="name" />
            </div>
            <div>
              <label
                class="label"
                for="email"
                :class="{ emailLblErrorClass: !email.valid }"
                >{{ $t("yourEmailAddressFormLbl") }}</label
              >
              <input
                type="email"
                name="email"
                id="email"
                required=""
                :class="{ email, error: !email.valid }"
                v-model="email.value"
              />
            </div>
            <div>
              <label class="label" for="file" :class="{ emailLblErrorClass: fileError }"
                >CV</label
              >
              <div
                :class="[wrapperBind]"
                :data-text="inputText"
                :uploadText="uploadBtnLbl"
              >
                <input
                  v-if="!uploadedFile"
                  name="file-upload-field"
                  type="file"
                  accept=".pdf"
                  class="file-upload-field"
                  value=""
                  @change="onFileChange"
                />
                <input
                  v-else
                  name="file-upload-field"
                  type=""
                  class="file-upload-field"
                  value=""
                  @click="resetFile()"
                />
              </div>
            </div>

            <div style="margin-bottom: 17px">
              <label class="label" for="textarea">{{ $t("textArealblForm") }}</label>
              <textarea
                class="message"
                name="textarea"
                id="textarea"
                required=""
                v-model="message.text"
                :maxlength="message.maxlength"
              ></textarea>
              <span class="counter"
                >{{ message.text.length }} / {{ message.maxlength }}</span
              >
            </div>
            <div style="margin-top: 0px; margin-bottom: 0px" v-show="!email.valid">
              <p class="weNeedYourEmail">
                {{ $t("weNeedYourEmailAddrToLbl") }}
              </p>
            </div>
            <div style="margin-top: 0px; margin-bottom: 0px" v-show="err">
              <p class="weNeedYourEmail">
                {{ $t("errSubmittingContact") }}
              </p>
            </div>
            <div style="margin-top: 0px; margin-bottom: 0px" v-show="fileError">
              <p class="weNeedYourEmail">
                {{ $t("pleaseUpluadAPdfFile") }}
              </p>
            </div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              sitekey="6Lej2owaAAAAALmhhOl0ctOAOkzU82v3vv1SQTNS"
            >
            </vue-recaptcha>
            <div style="margin-top: 17px">
              <input
                type="submit"
                :value="submitted ? $t('thankYouFor') : $t('sendMassageBtn')"
                :disabled="submitted"
                :class="submitted ? 'submittedClass' : ''"
              />
            </div>
          </fieldset>
        </form>
      </div>
    </section>
    <section class="footer" id="footer-section">
      <div class="footer__social-media">
        <div
          class="footer__social-media__item"
          v-for="option in socialMediaOptions"
          :key="option.id"
        >
          <div
            @click="goToPage(option.link)"
            class="div-background-img"
            :style="{
              backgroundImage: 'url(' + require(`../assets/${option.image}`) + ')',
            }"
          ></div>
        </div>
      </div>
      <div class="footer__headline_2">
        <h1 v-if="isWindowWidthDesktop">De Run 4547 | 5503 LT Veldhoven</h1>
        <h1 v-else>
          de Run 4547 <br />
          5503 LT Veldhoven
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
import { jobs } from "../variables/jobs.js";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
export default {
  data() {
    return {
      uploadBtnLbl: this.$t("uploadLbl"),
      fileError: null,
      availableJobs: jobs,
      selectedJob: jobs[0],
      uploadedFile: null,
      inputText: this.$t("addYourCvLbl"),
      inWidth: 0,
      base64pdf: null,
      err: false,
      socialMediaOptions: [
        {
          id: 4,
          link: "https://www.linkedin.com/company/spinnov/",
          image: "linkedinTeam.svg",
        },
      ],
      name: "",
      email: {
        value: "",
        valid: true,
      },
      message: {
        text: "",
        maxlength: 500,
      },
      submitted: false,
    };
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  created() {
    let ckeditor = document.createElement("script");
    ckeditor.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.head.appendChild(ckeditor);
  },
  computed: {
    wrapperBind() {
      return {
        "file-upload-wrapperRemove": this.uploadedFile,
        "file-upload-wrapper": !this.uploadedFile,
      };
    },
    isWindowWidthDesktop() {
      if (this.inWidth <= 600) {
        return false;
      }
      return true;
    },
  },
  methods: {
    goToPage(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    resetFile() {
      this.uploadedFile = null;
      this.inputText = this.$t("addYourCvLbl");
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (files[0].type === "application/pdf") {
        this.uploadedFile = files[0];
        this.inputText = files[0].name;
        this.getBase64(this.uploadedFile).then((data) => (this.base64pdf = data));
        this.fileError = false;
      } else {
        this.fileError = true;
      }
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
    submit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      // ! handling CV file format
      try {
        let cv = this.base64pdf.replace("data:application/pdf;base64,", "");

        await axios.post(`https://api.spinnov.com/api/job-application`, {
          name: this.name,
          email: this.email.value,
          message: this.message.text,
          job_title: this.selectedJob.title,
          cv: cv,
          recaptchaToken: recaptchaToken,
        });
        this.submitted = true;
        this.err = false;
        this.fileError = false;
      } catch (e) {
        this.err = true;
        this.fileError = true;

        this.submitted = false;
      }
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    // validate by type and value
    validate: function (type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function (value) {
      return emailRegExp.test(value);
    },
    setSelectedJob() {
      for (let index = 0; index < this.availableJobs.length; index++) {
        if (
          String(this.availableJobs[index].id) ===
          this.$route.path.substring(1).split("/")[1]
        ) {
          this.selectedJob = this.availableJobs[index];
          return;
        }
      }
      this.$router.replace("/");
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
    this.setSelectedJob();
  },
  watch: {
    // watching nested property
    "email.value": function (value) {
      this.validate("email", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";
.jobApplication {
  overflow-y: scroll;
  height: calc(100vh - 115.2px);

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }
}
.formSection {
  min-height: 90vh;
  padding: 5rem 5rem;
  background-color: #f6f6f6;
  @include respond(desktop) {
    padding: 5rem 5rem;
  }

  @include respond(laptop) {
    padding: 5rem 5rem;
  }

  @include respond(small-laptop) {
    padding: 4.5rem 4.5rem;
  }

  @include respond(tablet-land) {
    padding: 4rem 4rem;
  }

  @include respond(phone-land) {
    padding: 2rem 2rem;
  }
  &_form {
    padding: 5rem 0rem;
    margin: 0rem auto 0rem auto;
    background-color: #ffffff;
    width: 70%;
    @include respond(desktop) {
      width: 70%;
    }

    @include respond(laptop) {
      width: 75%;
    }

    @include respond(small-laptop) {
      width: 75%;
    }

    @include respond(tablet-land) {
      width: 85%;
    }

    @include respond(phone-land) {
      width: 95%;
    }
    &__textCont {
      display: block;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      margin-bottom: 3rem;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      @include respond(desktop) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      @include respond(laptop) {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
      }

      @include respond(small-laptop) {
        margin-top: 2.2rem;
        margin-bottom: 2.2rem;
      }

      @include respond(tablet-land) {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      @include respond(phone-land) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
      &__logo {
        text-align: center;
        font-family: "Montserrat";
        font-weight: 600;
        color: #000000;
        font-size: 5.6rem;
        opacity: 0.5;
        line-height: 1.21;
        letter-spacing: 8.4px;
        @include respond(desktop) {
          font-size: 5.6rem;
          letter-spacing: 8.4px;
        }

        @include respond(laptop) {
          font-size: 5.3rem;
          letter-spacing: 8px;
        }

        @include respond(small-laptop) {
          font-size: 5rem;
          letter-spacing: 7px;
        }

        @include respond(tablet-land) {
          font-size: 4.8rem;
          letter-spacing: 6.5px;
        }

        @include respond(phone-land) {
          font-size: 4.5rem;
          letter-spacing: 6px;
        }
      }
      &__jobPos {
        text-align: center;
        font-family: "Raleway";
        font-weight: bold;
        color: #000000;
        font-size: 8rem;
        line-height: 1;
        @include respond(desktop) {
          font-size: 8rem;
        }

        @include respond(laptop) {
          font-size: 7.5rem;
        }

        @include respond(small-laptop) {
          font-size: 7rem;
        }

        @include respond(tablet-land) {
          font-size: 6.2rem;
        }

        @include respond(phone-land) {
          font-size: 5rem;
        }
      }
      &__Desc {
        text-align: center;
        font-family: "Open Sans";
        color: #0b0b0b;
        font-size: 2.4rem;
        line-height: 1.38;
        opacity: 0.6;
        width: 50%;
        margin: 0 auto 0 auto;

        @include respond(desktop) {
          font-size: 2.4rem;
        }

        @include respond(laptop) {
          font-size: 2.3rem;
        }

        @include respond(small-laptop) {
          font-size: 2.1rem;
        }

        @include respond(tablet-land) {
          font-size: 2.3rem;
          width: 70%;
        }

        @include respond(phone-land) {
          font-size: 2rem;
          width: 80%;
        }
      }
    }
  }
}

.vue-form {
  font-size: 16px;
  padding: 0px 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 65%;
  @include respond(desktop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(laptop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(small-laptop) {
    font-size: 2rem;
    width: 70%;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
    width: 75%;
  }

  @include respond(phone-land) {
    margin-top: 0rem;
    font-size: 2rem;
    width: 85%;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
}
.vue-form div {
  position: relative;
  margin: 20px 0;
}
.vue-form h4,
.vue-form .label {
  font-family: "Raleway";
  font-size: 3.2rem;
  font-weight: bold;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 10px;

  @include respond(desktop) {
    font-size: 3rem;
  }

  @include respond(laptop) {
    font-size: 2rem;
  }

  @include respond(small-laptop) {
    font-size: 2rem;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
  }

  @include respond(phone-land) {
    font-size: 2rem;
  }
}
.file-upload-wrapperRemove {
  $defaultColor: #e73151;
  $height: 60px;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;

  position: relative;
  width: 100%;
  height: $height;
  @include respond(desktop) {
    height: 60px;
  }

  @include respond(laptop) {
    height: 55px;
  }

  @include respond(small-laptop) {
    height: 50px;
  }

  @include respond(tablet-land) {
    height: 45px;
  }

  @include respond(phone-land) {
    height: 40px;
  }
  &:after {
    content: attr(data-text);
    font-family: "Open Sans";
    font-size: 2.5rem;
    position: absolute;
    top: 0;
    left: 0;
    background: #f0f0f0;
    padding: 0px 7px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: $height;
    line-height: $height;
    border-radius: 0px;
    color: #363636;

    @include respond(desktop) {
      font-size: 2.5rem;
      height: 60px;
      line-height: 60px;
    }

    @include respond(laptop) {
      font-size: 2.5rem;
      height: 55px;
      line-height: 55px;
    }

    @include respond(small-laptop) {
      font-size: 2.3rem;
      height: 50px;
      line-height: 50px;
    }

    @include respond(tablet-land) {
      font-size: 2rem;
      height: 45px;
      line-height: 45px;
    }

    @include respond(phone-land) {
      font-size: 1.8rem;
      height: 40px;
      line-height: 40px;
    }
  }

  &:before {
    content: "REMOVE";
    position: absolute;
    font-family: "Open Sans";
    font-size: 2rem;
    top: 0;
    right: 0;
    display: inline-block;
    height: 60px;
    background: $defaultColor;
    color: #f0f0f0;
    font-weight: 700;
    z-index: 25;
    line-height: $height;
    padding: 0px 7px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0px;

    @include respond(desktop) {
      font-size: 2rem;
      height: 60px;
      line-height: 60px;
    }

    @include respond(laptop) {
      font-size: 1.8rem;
      height: 55px;
      line-height: 55px;
    }

    @include respond(small-laptop) {
      font-size: 1.7rem;
      height: 50px;
      line-height: 50px;
    }

    @include respond(tablet-land) {
      font-size: 1.6rem;
      height: 45px;
      line-height: 45px;
    }

    @include respond(phone-land) {
      font-size: 1.5rem;
      height: 40px;
      line-height: 40px;
    }
  }

  &:hover {
    &:before {
      background: darken($defaultColor, 10%);
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: $height;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
}
.file-upload-wrapper {
  $defaultColor: #4e3ee0;
  $height: 60px;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  position: relative;
  width: 100%;
  height: $height;

  @include respond(desktop) {
    height: 60px;
  }

  @include respond(laptop) {
    height: 55px;
  }

  @include respond(small-laptop) {
    height: 50px;
  }

  @include respond(tablet-land) {
    height: 45px;
  }

  @include respond(phone-land) {
    height: 40px;
  }
  &:after {
    content: attr(data-text);
    font-family: "Open Sans";
    font-size: 2.5rem;
    position: absolute;
    top: 0;
    left: 0;
    background: #f0f0f0;
    padding: 0px 7px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: $height;
    line-height: $height;
    border-radius: 0px;
    color: #363636;

    @include respond(desktop) {
      font-size: 2.5rem;
      height: 60px;
      line-height: 60px;
    }

    @include respond(laptop) {
      font-size: 2.5rem;
      height: 55px;
      line-height: 55px;
    }

    @include respond(small-laptop) {
      font-size: 2.3rem;
      height: 50px;
      line-height: 50px;
    }

    @include respond(tablet-land) {
      font-size: 2rem;
      height: 45px;
      line-height: 45px;
    }

    @include respond(phone-land) {
      font-size: 1.8rem;
      height: 40px;
      line-height: 40px;
    }
  }

  &:before {
    content: attr(uploadText);
    position: absolute;
    font-family: "Open Sans";
    font-size: 2rem;
    top: 0;
    right: 0;
    display: inline-block;
    height: $height;
    background: $defaultColor;
    color: #f0f0f0;
    font-weight: 700;
    z-index: 25;
    line-height: $height;
    padding: 0px 7px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0px;

    @include respond(desktop) {
      font-size: 2rem;
      height: 60px;
      line-height: 60px;
    }

    @include respond(laptop) {
      font-size: 1.8rem;
      height: 55px;
      line-height: 55px;
    }

    @include respond(small-laptop) {
      font-size: 1.7rem;
      height: 50px;
      line-height: 50px;
    }

    @include respond(tablet-land) {
      font-size: 1.6rem;
      height: 45px;
      line-height: 45px;
    }

    @include respond(phone-land) {
      font-size: 1.5rem;
      height: 40px;
      line-height: 40px;
    }
  }

  &:hover {
    &:before {
      background: darken($defaultColor, 10%);
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: $height;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
}
.vue-form .label {
  display: block;
}
.vue-form input,
.vue-form textarea,
.vue-form label {
  color: #363636;
}
.vue-form input[type="file"],
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form legend {
  display: block;
  width: 100%;
  appearance: none;
}
.vue-form input[type="file"],
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea {
  padding: 12px;

  font-family: "Open Sans";
  font-size: 2.5rem;
  border: none;
  background-color: #f0f0f0;

  @include respond(desktop) {
    font-size: 2.5rem;
  }

  @include respond(laptop) {
    font-size: 2.5rem;
  }

  @include respond(small-laptop) {
    font-size: 2.3rem;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
  }

  @include respond(phone-land) {
    font-size: 1.8rem;
  }
}
.emailLblErrorClass {
  color: #e73252 !important;
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus {
  outline: none;
}
.vue-form textarea {
  min-height: 220px;
  resize: none;
  overflow: auto;
}
.vue-form input[type="submit"] {
  border: none;
  background: #4e3ee0;
  padding: 20px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  width: 100%;
}
.no-touch .vue-form input[type="submit"]:hover {
  background: #42a2e1;
}
.vue-form input[type="submit"]:active {
  transform: scale(0.9);
}
.vue-form .error-message {
  height: 35px;
  margin: 0px;
}
.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 16px;
}
.vue-form .error {
  border-color: #e94b35 !important;
}
.vue-form .counter {
  background-color: #ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}
.fieldset {
  border: 0px;
}
.weNeedYourEmail {
  font-family: "Open Sans";
  font-size: 2rem;
  color: #e73252;
  opacity: 0.8;

  @include respond(desktop) {
    font-size: 2rem;
  }

  @include respond(laptop) {
    font-size: 1.8rem;
  }

  @include respond(small-laptop) {
    font-size: 1.8rem;
  }

  @include respond(tablet-land) {
    font-size: 1.6rem;
  }

  @include respond(phone-land) {
    font-size: 1.6rem;
  }
}
.footer {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 10rem 0rem;
  background-color: #f6f6f6;

  @include respond(laptop) {
    padding: 8rem 0rem 15rem 0rem;
  }

  @include respond(small-laptop) {
    padding: 5rem 0rem 15rem 0rem;
  }

  @include respond(tablet-land) {
    padding: 5rem 0rem 15rem 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    padding: 5rem 0rem 15rem 0rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    margin-top: 5rem;
    padding-bottom: 8rem;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 4.5rem;
      font-weight: 300;
      text-align: left;
      color: #110b47;
      opacity: 0.6;
      text-align: center;
      @include respond(desktop) {
        font-size: 4.5rem;
      }

      @include respond(laptop) {
        font-size: 4.2rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 3.8rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Montserrat";
      font-size: 13.7rem;
      font-weight: 600;
      text-align: center;
      color: #110b47;
      @include respond(desktop) {
        font-size: 13.7rem;
      }

      @include respond(laptop) {
        font-size: 12rem;
      }

      @include respond(small-laptop) {
        font-size: 11rem;
      }

      @include respond(tablet-land) {
        font-size: 10rem;
      }

      @include respond(phone-land) {
        font-size: 9rem;
      }
    }
  }
  &__social-media {
    display: flex;
    justify-content: center; // space-between when more than one
    align-items: center;
    max-width: 50rem;
    margin: 5rem auto 5rem auto;

    @include respond(desktop) {
      margin: 5rem auto 5rem auto;
      max-width: 50rem;
    }

    @include respond(laptop) {
      margin: 5rem auto 5rem auto;
      max-width: 45rem;
    }

    @include respond(small-laptop) {
      margin: 4rem auto 4rem auto;
      max-width: 45rem;
    }

    @include respond(tablet-land) {
      margin: 4rem auto 5rem auto;
      max-width: 35rem;
    }

    @include respond(phone-land) {
      margin: 3rem auto 3rem auto;
      max-width: 30rem;
    }

    &__item {
      cursor: pointer;

      & > div {
        width: 7rem;
        height: 7rem;

        @include respond(desktop) {
          width: 7rem;
          height: 7rem;
        }

        @include respond(laptop) {
          width: 6.2rem;
          height: 6.2rem;
        }

        @include respond(small-laptop) {
          width: 6rem;
          height: 6rem;
        }

        @include respond(tablet-land) {
          width: 5.5rem;
          height: 5.5rem;
        }

        @include respond(phone-land) {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}
.submittedClass {
  outline: none;
  background: #4bb543 !important;
  cursor: not-allowed !important;
}
</style>
